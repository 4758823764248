import React from "react";
import "./ErrorPage404.css";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { Link, Navigate, useNavigate } from "react-router-dom";

const ErrorPage404 = () => {
    return (
        <div className="ErrorPage404">
            <div className="inner-content">
                <div className="picture-content">
                    <SentimentVeryDissatisfiedIcon style={{ fontSize: "300px" }} />
                </div>
                <div className="text-content">
                    <div style={{ fontSize: "150px" }}>404</div>
                    <div style={{ fontSize: "50px" }}>OOPS!</div>
                    <div style={{ fontSize: "25px" }}>ページがありません</div>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage404;
