import logo from "./logo.svg";
import "./App.css";

import Container from "@mui/material/Container";
import React, { useState } from "react";
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import ProgressPage from "./pages/ProgressPage";
import MasterPage from "./pages/MasterPage";
import LoginPage from "./pages/LoginPage";
import CustomerPage from "./pages/CustomerPage";
import ErrorPage from "./pages/ErrorPage";
function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Routes>
                    {/* <Route path="/" element={<LoginPage />}></Route> */}
                    <Route path="/progress" element={<ProgressPage />}></Route>
                    <Route path="/master" element={<MasterPage />}></Route>
                    <Route path="/login" element={<LoginPage />}></Route>
                    <Route path="/admin-login" element={<LoginPage />}></Route>
                    <Route path="/tracking" element={<CustomerPage />}></Route>
                    <Route path="/*" element={<ErrorPage />}></Route>
                </Routes>
                {/* <ProgressPage></ProgressPage> */}
            </div>
        </BrowserRouter>
    );
}

export default App;
