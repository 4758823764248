import React, { useState, useEffect } from "react";
import "./ProgressInput.css";
import { Grid, Button, FormControl, InputLabel, Input, TextField, Checkbox } from "@mui/material";
import { height } from "@mui/system";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider, MobileDatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDate } from "date-fns";
import { useForm } from "react-hook-form";
import * as constComponent from "../constComponent";
import axios from "axios";
import cookies from "react-cookies";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ProgressInput = ({ changeData, myInput, setMessage, myMessage, changeCsvFlag }) => {
    const [blur, setblur] = useState(false);
    const [disableFlag, setDisableFlag] = useState(false);
    const forSubmit = () => {
        let mySubmitFlag = true;
        if (myInput.submitFlag) {
            mySubmitFlag = false;
        } else {
            mySubmitFlag = true;
        }
        changeData({
            ...myInput,
            submitFlag: mySubmitFlag,
        });
    };
    const myClear = () => {
        changeData({
            ...myInput,
            id: "",
            startDate: null,
            endDate: null,
            sendFlag: false,
            batsuFlag: false,
            maruFlag: true,
        });
    };

    useEffect(() => {
        // console.log(myInput);
        if (!myInput.batsuFlag && !myInput.maruFlag) {
            setDisableFlag(true);
        } else {
            setDisableFlag(false);
        }
    }, [myInput.batsuFlag, myInput.maruFlag]);
    const csvHandler = (event) => {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        setblur(true);
        axios({
            // headers: { "Content-Type": "multipart/form-data" },
            method: "post",
            data: formData,
            url: constComponent.urlAddress + "api/getCsv",
        })
            .then((Response) => {
                // console.log(Response.data);
                // console.log(Response.data[0]);
                setblur(false);
                if (Response.data[0] == "0") {
                    setMessage(Response.data[1], "info");
                } else {
                    setMessage(Response.data[1], "error");
                }
                changeCsvFlag();
            })
            .catch((error) => {
                setblur(false);
                setMessage("取込に失敗しました。システム管理者にお問い合わせください。", "error");
            });
    };
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const CustomButtonBlack = styled(Button)({
        fontFamily: ["Klee One"].join(","),
        fontSize: "16px",
        backgroundColor: "rgb(38, 43, 60)",
        "&:hover": {
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
        },
        // "&:focus": {
        //   boxShadow: "0 0 0 0.2rem rgb(38, 43, 60)",
        // },
    });
    const CustomButtonOrange = styled(Button)({
        fontFamily: ["Klee One"].join(","),
        fontSize: "16px",
        backgroundColor: "rgb(233, 85, 74)",
        "&:hover": {
            backgroundColor: "rgb(233, 85, 74)",
            borderColor: "rgb(233, 85, 74)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(233, 85, 74)",
            borderColor: "rgb(233, 85, 74)",
        },
        // "&:focus": {
        //   boxShadow: "0 0 0 0.2rem rgb(38, 43, 60)",
        // },
    });

    return (
        <div className="ProgressInput">
            <div className="InnerBox">
                <div className="TableBox">
                    <div style={{ margin: "auto" }}>
                        <form>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "24px",
                                        width: "780px",
                                    }}
                                >
                                    進捗確認
                                </div>
                                <div>
                                    <CustomButtonBlack
                                        variant="contained"
                                        className="buttonColor"
                                        style={{ width: "100px", height: "40px" }}
                                        component="label"
                                    >
                                        CSV取込 <input hidden accept=".csv" multiple type="file" onChange={csvHandler} />
                                    </CustomButtonBlack>
                                </div>
                            </div>

                            <div className="InputTable">
                                <div className="InputItem">
                                    <span className="v-line"></span>
                                    <div style={{ width: "150px" }}>お問い合わせ番号</div>
                                    <div style={{ width: "220px" }}>
                                        <TextField
                                            label=""
                                            id="outlined-size-small"
                                            size="small"
                                            value={myInput.id}
                                            fullWidth
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => {
                                                changeData({
                                                    ...myInput,
                                                    id: e.target.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "200px" }}>(完全一致)</div>
                                </div>
                                <div className="InputItem">
                                    <span className="v-line"></span>
                                    <div style={{ width: "150px" }}>到着日付</div>
                                    <div style={{ width: "220px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                // label="For mobile"
                                                inputFormat="yyyy-MM-dd"
                                                value={myInput.startDate}
                                                onChange={(newValue) => {
                                                    changeData({
                                                        ...myInput,
                                                        startDate: newValue,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField size="small" {...params} />}
                                            ></DesktopDatePicker>
                                        </LocalizationProvider>
                                    </div>
                                    <div style={{ marginLeft: "0", marginRight: "50px" }}>~</div>
                                    <div style={{ width: "220px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                                // label="Basic example"
                                                inputFormat="yyyy-MM-dd"
                                                value={myInput.endDate}
                                                onChange={(newValue) => {
                                                    changeData({
                                                        ...myInput,
                                                        endDate: newValue,
                                                    });
                                                }}
                                                renderInput={(params) => <TextField size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="InputItem">
                                    <span className="v-line"></span>
                                    <div style={{ width: "140px" }}>発送済のみ</div>
                                    <div style={{ width: "220px" }}>
                                        <Checkbox
                                            {...label}
                                            value={myInput.sendFlag}
                                            checked={myInput.sendFlag}
                                            style={{ color: "rgb(38, 43, 60)" }}
                                            onChange={(e) => {
                                                changeData({
                                                    ...myInput,
                                                    sendFlag: e.target.checked,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="InputItem">
                                    <span className="v-line"></span>
                                    <div style={{ width: "140px" }}>
                                        判定結果<span className="come">※</span>{" "}
                                    </div>
                                    <div style={{ width: "500px", display: "flex", marginRight: "20px" }}>
                                        <div style={{ marginRight: "0" }}>
                                            <Checkbox
                                                {...label}
                                                checked={myInput.maruFlag}
                                                value={myInput.maruFlag}
                                                style={{ color: "rgb(38, 43, 60)" }}
                                                onChange={(e) => {
                                                    changeData({
                                                        ...myInput,
                                                        maruFlag: e.target.checked,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <span style={{ fontSize: "21px", display: "flex", alignItems: "center", marginRight: "20px" }}>
                                            {" "}
                                            〇
                                        </span>

                                        <div style={{ marginRight: "0" }}>
                                            <Checkbox
                                                {...label}
                                                checked={myInput.batsuFlag}
                                                value={myInput.batsuFlag}
                                                style={{ color: "rgb(38, 43, 60)" }}
                                                onChange={(e) => {
                                                    changeData({
                                                        ...myInput,
                                                        batsuFlag: e.target.checked,
                                                    });
                                                }}
                                            />
                                        </div>

                                        <span style={{ fontSize: "21px", display: "flex", alignItems: "center" }}> ✕</span>
                                    </div>

                                    <div style={{}} className="InputItem-div">
                                        <CustomButtonBlack type="button" onClick={forSubmit} variant="contained" disabled={disableFlag}>
                                            検索
                                        </CustomButtonBlack>
                                    </div>
                                    <div style={{}} className="InputItem-div">
                                        <CustomButtonBlack onClick={myClear} variant="contained">
                                            クリア
                                        </CustomButtonBlack>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={blur}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
export default ProgressInput;
