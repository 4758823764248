import React, { useEffect, useState } from "react";
import "../CustomerInput/CustomerInput.css";
import { Grid, Button, FormControl, InputLabel, Input, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import * as constComponent from "../constComponent";
import { useForm } from "react-hook-form";

const CustomerInput = ({ searchTrigger }) => {
    const { register, handleSubmit } = useForm();
    const CustomButtonBlack = styled(Button)({
        fontFamily: ["Klee One"].join(","),
        fontSize: "1rem",
        backgroundColor: "rgb(38, 43, 60)",
        "&:hover": {
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
        },
    });
    const mySubmit = (data) => {
        searchTrigger(data.inquirySearchNum);
        // console.log(data);
    };
    // useEffect(() => {
    //     axios({
    //         method: "get",
    //         params: { inquirySearchNum: "102" },
    //         url: constComponent.urlAddress + "api/customerProgress",
    //     }).then((Response) => {
    //         console.log(Response.data);
    //     });
    // }, []);
    return (
        <form onSubmit={handleSubmit(mySubmit)}>
            <div className="CustomerInput">
                <div className="CustomerInnerBox">
                    <div className="InputInner">
                        <div style={{ fontSize: "1.5rem" }}>お客様確認</div>
                        <div>
                            <div className="InputItem" style={{ margin: "auto 10px auto 0" }}>
                                お問い合わせ番号
                            </div>
                            <div className="ProgressSearch">
                                <TextField
                                    id="outlined-size-small"
                                    inputProps={{ style: { fontSize: "1rem" }, maxLength: 10 }}
                                    style={{ width: "80%", margin: "auto 0 auto auto" }}
                                    size="small"
                                    {...register("inquirySearchNum")}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                            <CustomButtonBlack style={{ margin: "1.5rem 0 0 auto" }} type="submit" variant="contained">
                                検索
                            </CustomButtonBlack>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default CustomerInput;
