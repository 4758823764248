import React from "react";
import ErrorPage404 from "../components/ErrorPage/ErrorPage404";

const ErrorPage = () => {
    return (
        <div>
            <ErrorPage404> </ErrorPage404>
        </div>
    );
};

export default ErrorPage;
