import React, { useState, MouseEvent } from "react";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "/SIGOTO/moriProject/moriFront/src/myLogo.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import "../css/HeaderComponentCss.css";
import { styled } from "@mui/material/styles";
import "./CustomerHeaderComponent.css";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import EditIcon from "@mui/icons-material/Edit";
import AnalyticsIcon from "@mui/icons-material/Analytics";

const CustomerHeaderComponent = () => {
    const navigate = useNavigate();
    const CustomTypography = styled(Typography)({
        fontFamily: ["Klee One"].join(","),
    });
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const goProgress = () => {
        navigate("/progress");
        setAnchorElUser(null);
    };
    const goMaster = () => {
        navigate("/master");
        setAnchorElUser(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const settings = ["進捗確認", "通知用マスタ"];
    return (
        <header>
            <div className="CustomerHeaderComponent">
                <div className="CustomerInnerHeader">
                    <AppBar
                        position="static"
                        className="my-app-bar"
                        style={{
                            textAlign: "center",
                            backgroundColor: "white",
                            color: "black",
                        }}
                    >
                        <Toolbar>
                            <img src={logo}></img>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}></Typography>
                            {/* <Button color="inherit">Login</Button> */}
                            {/* <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={handleOpenUserMenu}
                            >
                                <MenuIcon />
                            </IconButton> */}
                            {/* <Menu
                                sx={{ mt: "45px" }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem onClick={goProgress}>
                                    <ListItemIcon>
                                        <AnalyticsIcon fontSize="small" />
                                    </ListItemIcon>
                                    <CustomTypography textAlign="center">進捗確認</CustomTypography>
                                </MenuItem>

                                <MenuItem onClick={goMaster}>
                                    <ListItemIcon>
                                        <EditIcon fontSize="small" />
                                    </ListItemIcon>
                                    <CustomTypography textAlign="center">マスタ</CustomTypography>
                                </MenuItem>
                                <MenuItem onClick={setLogOut}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <CustomTypography textAlign="center">ログアウト</CustomTypography>
                                </MenuItem>
                            </Menu> */}
                        </Toolbar>
                    </AppBar>
                </div>
            </div>
        </header>
    );
};

export default CustomerHeaderComponent;
