import React, { useState } from "react";
import LTable from "../components/LoginComponent/LTable";
import MySnackBarInfo from "../components/common/MySnackBarInfo";

const LoginPage = () => {
    const [myMessage, setMyMessage] = useState({
        message: "",
        type: "error",
        index: 0,
        vertical: "top",
    });
    const setMessage = (myText, mType) => {
        setMyMessage({
            message: myText,
            type: mType,
            index: myMessage.index + 1,
            vertical: "top",
        });
    };
    return (
        <div>
            <LTable setMessage={setMessage}></LTable>
            <MySnackBarInfo myMessage={myMessage} setMessage={setMessage}></MySnackBarInfo>
        </div>
    );
};
export default LoginPage;
