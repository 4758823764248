import { width } from "@mui/system";
import React, { useEffect, useState } from "react";
import TableComponent from "../components/Table/TableComponent";
import ProgressInput from "../components/ProgressInput/ProgressInput";
import HeaderComponent from "../components/common/HeaderComponent";
import "../css/Common.css";
// import MySnackBar from "../components/common/MySnackBar";
import MySnackBarInfo from "../components/common/MySnackBarInfo";
import axios from "axios";
import cookies from "react-cookies";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as constComponent from "../components/constComponent";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ProgressPage = () => {
    const navigate = useNavigate();

    const [myInput, setMyInput] = useState({
        id: "",
        startDate: null,
        endDate: null,
        sendFlag: false,
        batsuFlag: false,
        maruFlag: true,
        submitFlag: false,
    });
    const changeData = (value) => {
        setMyInput(value);
        // console.log(myInput);
    };

    const [myMessage, setMyMessage] = useState({
        message: "",
        type: "error",
        index: 0,
        vertical: "top",
    });
    const setMessage = (myText, mType) => {
        setMyMessage({
            message: myText,
            type: mType,
            index: myMessage.index + 1,
            vertical: "top",
        });
    };
    const [csvFlag, setCsvFlag] = React.useState(true);
    const changeCsvFlag = () => {
        if (csvFlag) {
            setCsvFlag(false);
        } else {
            setCsvFlag(true);
        }
    };

    const [open, setOpen] = React.useState(true);
    // const handleClose = () => {
    //     setOpen(false);
    // };
    useEffect(() => {
        setOpen(true);
        axios({
            method: "get",
            headers: {
                Authorization: `Bearer ${cookies.load("accessToken")}`,
            },
            url: constComponent.urlAddress + "api/member/me",
            withCredentials: true,
        })
            .then((Response) => {
                setOpen(false);
            })
            .catch((e) => {
                setOpen(false);
                navigate("/admin-login");
            });
    }, []);

    return (
        <div className="ProgressPage" style={{ backgroundColor: "rgb(236, 245, 244)" }}>
            {!open && (
                <div>
                    <HeaderComponent></HeaderComponent>
                    <ProgressInput
                        myInput={myInput}
                        changeData={changeData}
                        setMessage={setMessage}
                        myMessage={myMessage}
                        changeCsvFlag={changeCsvFlag}
                    ></ProgressInput>
                    <TableComponent myInput={myInput} csvFlag={csvFlag}></TableComponent>
                    <MySnackBarInfo myMessage={myMessage} setMessage={setMessage}></MySnackBarInfo>
                </div>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
export default ProgressPage;
