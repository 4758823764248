import React, { useEffect, useState } from "react";
import logo from "/SIGOTO/moriProject/moriFront/src/myLogo.svg";
import "./LTable.css";
import * as constComponent from "../constComponent";
import axios from "axios";
import { Grid, Button, FormControl, InputLabel, Input, TextField, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import cookies from "react-cookies";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const LTable = ({ setMessage }) => {
    const CustomButtonBlack = styled(Button)({
        fontFamily: ["Klee One"].join(","),
        fontSize: "16px",
        backgroundColor: "rgb(38, 43, 60)",
        "&:hover": {
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
        },
    });
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();
    const [account, setAccount] = useState({
        id: "",
        password: "",
    });

    useEffect(() => {
        setOpen(true);
        axios({
            method: "get",
            headers: {
                Authorization: `Bearer ${cookies.load("accessToken")}`,
            },
            url: constComponent.urlAddress + "api/member/me",
        })
            .then((Response) => {
                // console.log(Response.data);
                setOpen(false);
                navigate("/progress");
            })
            .catch((e) => {
                setOpen(false);
                // console.log("SHIT");
            });
    }, []);
    const loginSubmit = () => {
        axios({
            // headers: { "Access-Control-Allow-Origin": "http://localhost:8080" },
            method: "post",
            data: {
                email: account.id,
                password: account.password,
            },
            // withCrednetials: true,
            url: constComponent.urlAddress + "api/auth/login",
        })
            .then((Response) => {
                // console.log(Response.data.accessToken);
                // const expires = new Date();
                // console.log(expires.getTime() + 60);
                // let mTime = expires.getTime() + 1000 * 60 * 1;

                cookies.save("refreshToken", Response.data.refreshToken);
                cookies.save("accessToken", Response.data.accessToken);
                axios.defaults.headers.common["Authorization"] = `Bearer ${Response.data.accessToken}`;
                navigate("/progress");
            })
            .catch((e) => {
                console.log(e);
                // console.log("abcde");
                setMessage("ログインID又はパスワードが間違っております。", "error");
            });
    };

    // useEffect(() => {
    //     axios({
    //         // headers: { "Content-Type": "multipart/form-data" },
    //         method: "post",
    //         data: {
    //             email: "jeong123a",
    //             password: "1q2w3e4r",
    //         },

    //         url: constComponent.urlAddress + "auth/login",
    //     }).then((Response) => {
    //         console.log(Response.data.accessToken);
    //         cookies.save("refreshToken", Response.data.refreshToken);
    //         cookies.save("accessToken", Response.data.accessToken);
    //         axios.defaults.headers.common["Authorization"] = `Bearer ${Response.data.accessToken}`;
    //     });

    //     console.log(cookies.load("refreshToken"));

    //     axios({
    //         headers: {
    //             authorization: "Bearer " + cookies.load("accessToken"),
    //         },
    //         method: "get",
    //         url: "/member/me",
    //     }).then((Response) => {
    //         console.log(Response.data);
    //     });
    // }, []);

    return (
        <div className="LTable">
            {!open && (
                <div className="Inner-Table">
                    <div className="Content-Table">
                        <div className="LItem LItem-Title">
                            {" "}
                            <div>
                                <img src={logo} alt="" />
                            </div>
                        </div>
                        {/* <div className="LItem">asdasda</div> */}
                        {/* <div className="LItem LItem-Iput">
                        <TextField
                            label=""
                            id="outlined-size-small"
                            size="small"
                            // value={myInput.id}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                            }}
                        ></TextField>
                    </div> */}
                        <div className="LItem LItem-Iput">
                            <TextField
                                id="input-with-icon-textfield"
                                label="ログインID"
                                value={account.id}
                                autoComplete="false"
                                onChange={(e) => {
                                    setAccount({
                                        ...account,
                                        id: e.target.value,
                                    });
                                }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <AccountCircleIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                        </div>

                        <div className="LItem LItem-Iput">
                            <TextField
                                id="input-with-icon-textfield"
                                label="パスワード"
                                type="password"
                                value={account.password}
                                onChange={(e) => {
                                    setAccount({
                                        ...account,
                                        password: e.target.value,
                                    });
                                }}
                                inputProps={{ maxLength: 10 }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="standard"
                            />
                        </div>

                        {/* <div className="LItem LItem-Iput">
                        <TextField
                            label=""
                            id="outlined-size-small"
                            size="small"
                            // value={myInput.id}
                            fullWidth
                            // onChange={(e) => {
                            //     changeData({
                            //         ...myInput,
                            //         id: e.target.value,
                            //     });
                            // }}
                        />
                    </div> */}
                        <div className="LItem LItem-Iput">
                            <CustomButtonBlack type="button" onClick={loginSubmit} variant="contained">
                                ログイン
                            </CustomButtonBlack>
                        </div>
                    </div>
                </div>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
export default LTable;
