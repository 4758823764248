import React, { useState, useEffect, useRef } from "react";
import "./MasterComponent.css";
import { Grid, Button, FormControl, InputLabel, Input, TextField, Checkbox } from "@mui/material";
import { height, textAlign } from "@mui/system";
import { styled } from "@mui/material/styles";
import { DatePicker, LocalizationProvider, MobileDatePicker, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { setDate } from "date-fns";
import { useForm } from "react-hook-form";
import * as constComponent from "../constComponent";
import axios from "axios";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import cookies from "react-cookies";
import { Link, Navigate, useNavigate } from "react-router-dom";

const MasterComponent = ({ setMessage, myMessage }) => {
    const label = { inputProps: { "aria-label": "Checkbox demo" } };
    const emailRef = useRef();
    const dayRef = useRef();

    const [emailError, setEmailError] = useState(false);
    const [dayError, setDayError] = useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        content: "",
        progressDay: "",
    });
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const navigate = useNavigate();
    const CustomButtonBlack = styled(Button)({
        color: "white",
        fontFamily: ["Klee One"].join(","),
        fontSize: "16px",
        backgroundColor: "rgb(38, 43, 60)",
        "&:hover": {
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(38, 43, 60)",
            borderColor: "rgb(38, 43, 60)",
        },
        // "&:focus": {
        //   boxShadow: "0 0 0 0.2rem rgb(38, 43, 60)",
        // },
    });
    const CustomButtonOrange = styled(Button)({
        color: "white",
        fontFamily: ["Klee One"].join(","),
        fontSize: "16px",
        backgroundColor: "rgb(233, 85, 74)",
        "&:hover": {
            backgroundColor: "rgb(233, 85, 74)",
            borderColor: "rgb(233, 85, 74)",
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "none",
            backgroundColor: "rgb(233, 85, 74)",
            borderColor: "rgb(233, 85, 74)",
        },
    });
    useEffect(() => {
        let mAuthorization = "Bearer " + cookies.load("accessToken");
        // console.log(cookies.load("accessToken"));
        axios({
            headers: {
                Authorization: mAuthorization,
            },
            method: "get",
            url: constComponent.urlAddress + "api/getMaster",
        })
            .then((Response) => {
                // console.log("Avcde");
                // console.log(Response);
                setFormData({
                    email: Response.data.email,
                    content: Response.data.content,
                    progressDay: Response.data.progressDay,
                });
            })
            .catch((e) => {
                console.log(e);
                navigate("/admin-login");
            });
    }, []);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    function emailValidationHandler(email, submitFlag) {
        // console.log(email);
        // console.log(email.trim());
        if (email.trim() == "") {
            setMessage("メールアドレスを入力してください。", "error");
            setEmailError(true);
            if (submitFlag == 1) {
                emailRef.current.focus();
            }
            return true;
            // emailRef.current.focus();
        } else if (!isValidEmail(email)) {
            setMessage("メールアドレスの形式で入力してください。", "error");
            setEmailError(true);
            if (submitFlag == 1) {
                emailRef.current.focus();
            }
            // emailRef.current.focus();
            return true;
        } else {
            setEmailError(false);
            return false;
        }
    }
    function dayEmptyError(v, submitFlag) {
        // console.log("in");
        if (v.trim() == "") {
            if (submitFlag == 1) {
                dayRef.current.focus();
            }
            setMessage("進捗期限を入力してください。", "error");
            setDayError(true);
            return true;
        } else {
            setDayError(false);
            return false;
        }
    }
    const mSubmit = () => {
        let mAuthorization = "Bearer " + cookies.load("accessToken");
        axios({
            headers: {
                Authorization: mAuthorization,
            },
            method: "post",
            data: formData,
            url: constComponent.urlAddress + "api/setMaster",
        })
            .then((Response) => {
                // console.log(Response.data);
                setOpen(false);
                setMessage("通知情報を保存しました。", "info");
            })
            .catch((e) => {
                setOpen(false);
            });
        setOpen(false);
        // console.log(formData);
    };
    const formValidation = () => {
        if (emailValidationHandler(formData.email, 1)) {
        } else if (dayEmptyError(formData.progressDay, 1)) {
        } else {
            setOpen(true);
        }
    };

    return (
        <div className="MasterComponent">
            <div className="mInnerBox">
                <div className="mTableBox">
                    <div style={{ margin: "auto" }}>
                        <form>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    alignContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "24px",
                                        width: "780px",
                                    }}
                                >
                                    通知用マスタ
                                </div>
                            </div>

                            <div className="mInputTable">
                                <div className="mInputItem">
                                    <span className="v-line"></span>
                                    <div>
                                        メールアドレス<span className="comeA">※</span>
                                    </div>
                                </div>
                                <div className="mInputItem">
                                    <span style={{ marginRight: "10px" }}></span>
                                    <div style={{ width: "300px" }}>
                                        <TextField
                                            inputRef={emailRef}
                                            label=""
                                            value={formData.email}
                                            inputProps={{ maxLength: 100 }}
                                            onChange={(e) => {
                                                setFormData({
                                                    ...formData,
                                                    email: e.target.value,
                                                });
                                            }}
                                            error={emailError}
                                            onBlurCapture={(e) => emailValidationHandler(e.target.value, 0)}
                                            id="outlined-size-small"
                                            size="small"
                                            autoComplete="off"
                                            fullWidth
                                        />
                                    </div>
                                </div>

                                <div className="mInputItem">
                                    <span className="v-line"></span>
                                    <div>全体メッセージ（ユーザー画面表示）</div>
                                </div>
                                <div className="mInputItem">
                                    <span style={{ marginRight: "10px" }}></span>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="50文字まで可能です。"
                                        value={formData.content}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                content: e.target.value,
                                            });
                                        }}
                                        inputProps={{ maxLength: 50 }}
                                        style={{ width: "860px" }}
                                        multiline={true}
                                        rows={2}
                                        onKeyUp={(e) => {
                                            var lineList = e.target.value.split("\n");

                                            // console.log(lineList);
                                            if (lineList.length > 2 && e.key === "Enter") {
                                                // console.log("YES");
                                                setFormData({
                                                    ...formData,
                                                    content: lineList[0] + "\n" + lineList[1],
                                                });
                                            }
                                            // console.log(e.target.value);
                                        }}
                                        variant="outlined"
                                        autoComplete="off"
                                    />
                                </div>

                                <div className="mInputItem">
                                    <span className="v-line"></span>
                                    <div style={{ width: "150px" }}>
                                        進捗期限<span className="comeA">※</span>
                                    </div>
                                    <div style={{ width: "500px", display: "flex", alignItems: "center" }}>
                                        <div style={{ width: "60px" }}>
                                            <TextField
                                                style={{ textAlign: "center", display: "flex", margin: "0 auto" }}
                                                label=""
                                                sx={{ input: { textAlign: "center" } }}
                                                inputRef={dayRef}
                                                onInput={(e) =>
                                                    (e.target.value = e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1"))
                                                }
                                                value={formData.progressDay}
                                                onChange={(e) => {
                                                    setFormData({
                                                        ...formData,
                                                        progressDay: e.target.value,
                                                    });
                                                }}
                                                autoComplete="off"
                                                error={dayError}
                                                onBlurCapture={(e) => dayEmptyError(e.target.value, 0)}
                                                inputProps={{ maxLength: 2 }}
                                                id="outlined-size-small"
                                                size="small"
                                                fullWidth
                                            />
                                        </div>
                                        <div style={{ width: "140px" }}>日以降</div>
                                    </div>
                                    <div style={{ marginLeft: "auto", marginRight: "35px" }}>
                                        <CustomButtonBlack variant="contained" onClick={formValidation}>
                                            登録
                                        </CustomButtonBlack>
                                        {/* <CustomButtonBlack type="submit" variant="contained">
                                            登録
                                        </CustomButtonBlack> */}
                                    </div>
                                </div>
                            </div>
                            <Dialog
                                PaperProps={{ sx: { position: "fixed", top: 200, width: 450, height: 170, m: 5 } }}
                                style={{ margin: "auto auto" }}
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle
                                    id="alert-dialog-title"
                                    style={{ fontFamily: "Klee One", marginTop: "auto", marginRight: "auto", marginLeft: "auto" }}
                                >
                                    通知情報を登録します。よろしいですか？
                                </DialogTitle>
                                {/* <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Let Google help apps determine location. This means sending anonymous location data to Google, even
                                        when no apps are running.
                                    </DialogContentText>
                                </DialogContent> */}
                                <DialogActions style={{ marginBottom: "auto", marginTop: "5px", marginRight: "10px" }}>
                                    <CustomButtonBlack onClick={mSubmit}>OK</CustomButtonBlack>
                                    <Button onClick={handleClose} style={{ color: "rgb(38, 43, 60)" }} autoFocus>
                                        キャンセル
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </form>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};
export default MasterComponent;
