import React, { useEffect, useState } from "react";
import "../CustomerExplain/CustomerExplain.css";
import { Grid, Button, FormControl, InputLabel, Input, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import * as constComponent from "../constComponent";
import { useForm } from "react-hook-form";

const CustomerExplain = () => {
    return (
        <div className="CustomerExplain">
            <div className="ExplainInnerBox">
                <div className="ExplainInputInner">
                    {/* <div style={{ fontSize: "1rem" }}>お問い合わせ番号</div> */}
                    <div className="ExplainInputItem" style={{ fontSize: "1rem", margin: "auto 10px auto 10px" }}>
                        【公式サイトよりお越しの方】
                    </div>

                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※お問い合わせ番号へは注文番号8桁をご入力ください。
                    </div>
                    <br></br>
                    <div className="ExplainInputItem" style={{ fontSize: "1rem", margin: "auto 10px auto 10px" }}>
                        【楽天サイトよりお越しの方】
                    </div>
                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※お問い合わせ番号へは楽天受注番号「269333-日付6桁－●●●●●●●●●●」の下10桁●部分のみご入力ください。
                    </div>
                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※このフォームに入力された情報は、楽天グループ株式会社ではなく、当店が管理しています。
                    </div>
                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※このフォームに入力された情報に関するお問い合わせは、直接当店にお願いいたします。楽天グループ株式会社では対応できません。
                    </div>
                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※このフォームで入力された情報につきましては、当店は、ご注文商品の進捗確認の目的でのみ利用します。
                    </div>
                    <br></br>
                    <div className="ExplainInputItem" style={{ fontSize: "1rem", margin: "auto 10px auto 10px" }}>
                        【Yahooショッピングよりお越しの方】
                    </div>

                    <div className="ExplainInputItem" style={{ fontSize: "0.8rem", margin: "auto 10px auto 20px" }}>
                        ※お問い合わせ番号へはYahoo注文ID「watayamori-●●●●●●●●」の下8桁●部分のみご入力ください。
                    </div>
                </div>
            </div>
        </div>
    );
};
export default CustomerExplain;
