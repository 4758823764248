import React, { useState } from "react";
import "../CustomerTable/CustomerTable.css";
import * as constComponent from "../constComponent";
import { Grid, Button, FormControl, InputLabel, Input, TextField } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useEffect } from "react";
import axios from "axios";
import CustomerExplain from "../CustomerExplain/CustomerExplain";

const CustomerTable = ({ inquiryData, myMessage, setMessage }) => {
    const [ngFlag, setNgFlag] = useState(false);
    const [customerForm, setCustomerForm] = useState({
        allNotification: "",
        emptyFlag: true,
        inquirySearchNo: "",
        ngContent: "",
        progressContent: "",
        s1Time: "",
        s2Time: "",
        s3Time: "",
        s4Time: "",
        s5Time: "",
    });
    useEffect(() => {
        // console.log(inquiryData);
        if (inquiryData.searchCount != 0) {
            if (inquiryData.inquirySearchNum.trim() == "") {
                setMessage("お問い合わせ番号を入力してください。", "error", "top");
            } else {
                axios({
                    method: "get",
                    params: { inquirySearchNum: inquiryData.inquirySearchNum },
                    url: constComponent.urlAddress + "api/customerProgress",
                }).then((Response) => {
                    let mData = Response.data;
                    if (mData.ngContent != "") {
                        setNgFlag(true);
                    } else {
                        setNgFlag(false);
                    }
                    if (mData.emptyFlag) {
                        setMessage("検索データがありません。", "error", "top");
                    }

                    setCustomerForm({
                        allNotification: mData.allNotification,
                        emptyFlag: mData.emptyFlag,
                        inquirySearchNo: mData.inquirySearchNo,
                        ngContent: mData.ngContent,
                        progressContent: mData.progressContent,
                        s1Time: mData.s1Time,
                        s2Time: mData.s2Time,
                        s3Time: mData.s3Time,
                        s4Time: mData.s4Time,
                        s5Time: mData.s5Time,
                    });

                    // console.log(Response.data);
                });
            }
        }
        // console.log(customerForm);
    }, [inquiryData.searchCount]);
    const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: constComponent.aokuro,
            color: "white",
        },
        fontFamily: ["Klee One"].join(","),
    }));
    const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
        color: "black",
        fontFamily: ["Klee One"].join(","),
    }));

    const StyledFooter = styled(TableFooter)(({ theme }) => ({
        fontFamily: ["Klee One"].join(","),
    }));
    const StyledRow = styled(TableRow)(({ theme }) => ({
        fontFamily: ["Klee One"].join(","),
    }));
    const theme = createTheme({
        components: {
            // Name of the component

            MuiTablePagination: {
                styleOverrides: {
                    // Name of the slot
                    toolbar: { fontFamily: "Klee One", color: "black" },
                    selectLabel: {
                        // fontFamily: "Klee One",
                        // color: "black",
                        display: "none",
                    },
                    select: { fontFamily: "Klee One", color: "black", fontWeight: "300" },
                    displayedRows: { fontFamily: "Klee One", color: "black" },
                    menuItem: { fontFamily: "Klee One", color: "black" },
                },
            },
        },
    });

    return (
        <div>
            {/* // <div className="CustomerTable" style={customerForm.allNotification.trim() != "" ? { height: "500px" } : { height: "400px" }}> */}
            {!customerForm.emptyFlag && (
                <div className="CustomerTable">
                    {!customerForm.emptyFlag && (
                        <div className="CustomerInnerTable">
                            <div style={{ width: "100%", display: "flex", margin: "auto auto" }}>
                                <div className="InnerTable">
                                    <div className="ContentDiv">
                                        <TableContainer>
                                            <Table
                                                sx={{ minWidth: 100 }}
                                                stickyHeader
                                                size="small"
                                                // aria-label="a dense table"
                                                aria-label="custom pagination table sticky"
                                            >
                                                <TableHead>
                                                    <TableRow style={{ width: "300px" }}>
                                                        <StyledTableCellHead
                                                            align="center"
                                                            sx={{
                                                                borderRight: 1.5,
                                                                borderLeft: 1.5,
                                                                borderColor: "white",
                                                                fontSize: "1rem",
                                                            }}
                                                        >
                                                            作業状況
                                                        </StyledTableCellHead>
                                                        <StyledTableCellHead
                                                            align="center"
                                                            sx={{
                                                                borderRight: 1.5,
                                                                borderColor: "white",
                                                                fontSize: "1rem",
                                                            }}
                                                        >
                                                            着手日時
                                                        </StyledTableCellHead>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            到着→解体
                                                        </StyledTableCellBody>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                        >
                                                            {customerForm.s1Time}
                                                        </StyledTableCellBody>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            中綿打ち直し
                                                        </StyledTableCellBody>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                        >
                                                            {customerForm.s2Time}
                                                        </StyledTableCellBody>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            出来→側生地
                                                        </StyledTableCellBody>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                        >
                                                            {customerForm.s3Time}
                                                        </StyledTableCellBody>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            綿入→袋入
                                                        </StyledTableCellBody>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                        >
                                                            {customerForm.s4Time}
                                                        </StyledTableCellBody>
                                                    </TableRow>
                                                    <TableRow>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                            component="th"
                                                            scope="row"
                                                        >
                                                            発送
                                                        </StyledTableCellBody>
                                                        <StyledTableCellBody
                                                            sx={{
                                                                borderRight: 1,
                                                                borderLeft: 1,
                                                                borderLeftColor: "rgb(224,224,224)",
                                                                borderRightColor: "rgb(224,224,224)",
                                                                fontSize: "1rem",
                                                            }}
                                                            align="center"
                                                        >
                                                            {customerForm.s5Time}
                                                        </StyledTableCellBody>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    {!ngFlag && (
                                        <div style={{ display: "block" }} className="ContentDiv">
                                            <div style={{ fontSize: "1.2rem" }}>
                                                <span className="v-line"></span>
                                                作業進捗内容
                                            </div>
                                            <div style={{ fontSize: "1rem" }}>
                                                <span style={{ marginRight: "15px" }}> </span>
                                                {customerForm.progressContent}
                                            </div>
                                        </div>
                                    )}

                                    {ngFlag && (
                                        <div style={{ display: "block" }} className="ContentDiv">
                                            <div style={{ fontSize: "1.2rem" }}>
                                                <span className="v-line"></span>
                                                NG内容
                                            </div>
                                            <div style={{ fontSize: "1rem", color: "red" }}>
                                                <span style={{ marginRight: "15px" }}></span>
                                                {customerForm.ngContent}
                                                {/* お布団の中身を覆う布地の直しが完了しました。 */}
                                            </div>
                                        </div>
                                    )}

                                    {customerForm.allNotification.trim() != "" && (
                                        <div style={{ display: "block" }} className="ContentDiv">
                                            <div style={{ fontSize: "1.2rem" }}>
                                                <span className="v-line"></span>
                                                全体メッセージ
                                            </div>
                                            <div style={{ fontSize: "1rem", margin: "0 15px 0 15px", wordWrap: "break-word" }}>
                                                {customerForm.allNotification.split("\n").map((m) => {
                                                    return (
                                                        <span key={m}>
                                                            {m}
                                                            <br />
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <CustomerExplain></CustomerExplain>
        </div>
    );
};
export default CustomerTable;
