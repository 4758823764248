import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MySnackBarInfo = ({ myMessage, setMessage }) => {
    const { message, type, index } = myMessage;
    const vertical = myMessage.vertical;
    const horizontal = "center";
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };
    React.useEffect(() => {
        console.log(index);
    });

    React.useEffect(() => {
        if (message != "") {
            setOpen(true);
        }
    }, [index]);
    // React.useEffect(() => {
    //     setMessage("", "error");
    //     return () => {
    //         console.log("cleanUp 함수");
    //     };
    // });

    return (
        <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose} message="wow">
                <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
                    <div>{message}</div>
                </Alert>
            </Snackbar>
        </Stack>
    );
};
export default MySnackBarInfo;
