import React, { useState, useEffect } from "react";
import MasterComponent from "../components/SettingMaster/MasterComponent";
import HeaderComponent from "../components/common/HeaderComponent";
import MySnackBarInfo from "../components/common/MySnackBarInfo";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import cookies from "react-cookies";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as constComponent from "../components/constComponent";
const MasterPage = () => {
    const navigate = useNavigate();
    const [myMessage, setMyMessage] = useState({
        message: "",
        type: "error",
        index: 0,
        vertical: "top",
    });
    const setMessage = (myText, mType) => {
        setMyMessage({
            message: myText,
            type: mType,
            index: myMessage.index + 1,
            vertical: "top",
        });
    };
    const [open, setOpen] = React.useState(true);
    // const handleClose = () => {
    //     setOpen(false);
    // };
    useEffect(() => {
        setOpen(true);
        axios({
            method: "get",
            headers: {
                Authorization: `Bearer ${cookies.load("accessToken")}`,
            },
            url: constComponent.urlAddress + "api/member/me",
            withCredentials: true,
        })
            .then((Response) => {
                setOpen(false);
            })
            .catch((e) => {
                setOpen(false);
                navigate("/admin-login");
            });
    }, []);

    return (
        <div>
            {!open && (
                <div>
                    <HeaderComponent></HeaderComponent>
                    <MasterComponent myMessage={myMessage} setMessage={setMessage}></MasterComponent>
                    <MySnackBarInfo myMessage={myMessage} setMessage={setMessage}></MySnackBarInfo>
                </div>
            )}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
export default MasterPage;
