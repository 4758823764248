import React, { useState } from "react";
import CustomerHeaderComponent from "../components/CustomerHeaderComponent/CustomerHeaderComponent";
import CustomerInput from "../components/CustomerInput/CustomerInput";
import CustomerTable from "../components/CustomerTable/CustomerTable";
import MySnackBarInfo from "../components/common/MySnackBarInfo";
import CustomerExplain from "../components/CustomerExplain/CustomerExplain";
const CustomerPage = () => {
    const [inquiryData, setInquiryData] = useState({
        inquirySearchNum: "",
        searchCount: 0,
    });
    const searchTrigger = (num) => {
        setInquiryData({
            inquirySearchNum: num,
            searchCount: inquiryData.searchCount + 1,
        });
    };
    const [myMessage, setMyMessage] = useState({
        message: "",
        type: "error",
        index: 0,
        vertical: "bottom",
    });
    const setMessage = (myText, mType, mVertical) => {
        setMyMessage({
            message: myText,
            type: mType,
            index: myMessage.index + 1,
            vertical: mVertical,
        });
    };
    return (
        <div className="CustomerPage">
            <CustomerHeaderComponent></CustomerHeaderComponent>
            <CustomerInput searchTrigger={searchTrigger}></CustomerInput>
            <CustomerTable inquiryData={inquiryData} myMessage={myMessage} setMessage={setMessage}></CustomerTable>

            <MySnackBarInfo myMessage={myMessage} setMessage={setMessage}></MySnackBarInfo>
        </div>
    );
};

export default CustomerPage;
