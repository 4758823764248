import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import "./TableComponent.css";
import TableHead from "@mui/material/TableHead";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import * as constComponent from "../constComponent";
import cookies from "react-cookies";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

function TablePaginationActions(props) {
    const theme = useTheme();

    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

function createData(no, calories, fat, a, b, c) {
    return { no, calories, fat, a, b, c };
}

function TableComponent({ myInput, csvFlag }) {
    const [tableChange, setTable] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const navigate = useNavigate();

    const [rows, setRows] = React.useState([]);
    const StyledTableCellHead = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: constComponent.aokuro,
            color: "white",
        },
        fontFamily: ["Klee One"].join(","),
    }));
    const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
        color: "black",
        fontFamily: ["Klee One"].join(","),
    }));
    const StyledPagination = styled(TablePagination)(({ theme }) => ({
        fontFamily: ["Klee One"].join(","),
    }));

    const StyledFooter = styled(TableFooter)(({ theme }) => ({
        fontFamily: ["Klee One"].join(","),
    }));
    const StyledRow = styled(TableRow)(({ theme }) => ({
        fontFamily: ["Klee One"].join(","),
    }));
    const theme = createTheme({
        components: {
            // Name of the component

            MuiTablePagination: {
                styleOverrides: {
                    // Name of the slot
                    toolbar: { fontFamily: "Klee One", color: "black" },
                    selectLabel: {
                        // fontFamily: "Klee One",
                        // color: "black",
                        display: "none",
                    },
                    select: { fontFamily: "Klee One", color: "black", fontWeight: "300" },
                    displayedRows: { fontFamily: "Klee One", color: "black" },
                    menuItem: { fontFamily: "Klee One", color: "black" },
                },
            },
        },
    });

    React.useEffect(() => {
        const myData = {
            inquiry_search_no: myInput.id,
            start_timeStamp: myInput.startDate,
            arrival_timeStamp: myInput.endDate,
            update_timeStamp: myInput.endDate,
            batsu_flag: myInput.batsuFlag,
            maru_flag: myInput.maruFlag,
            send_flag: myInput.sendFlag,
        };

        // console.log(myData);

        // console.log("Bearer " + cookies.load("accessToken"));
        // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

        let mAuthorization = "Bearer " + cookies.load("accessToken");
        axios.defaults.headers.common["Authorization"] = `Bearer ${cookies.load("accessToken")}`;
        axios({
            method: "post",
            data: myData,
            url: constComponent.urlAddress + "api/set",
            // url: constComponent.urlAddress + "member/me",
        })
            .then((Response) => {
                // console.log(Response);
                let mdata = Response.data;
                setRows(mdata);
                setPage(0);
                // console.log(rows);
            })
            .catch((e) => {
                navigate("/admin-login");
            });
    }, [myInput.submitFlag, csvFlag]);
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="TableComponent">
            <div className="InnerBox">
                <TableContainer>
                    <Table
                        sx={{ minWidth: 500 }}
                        stickyHeader
                        size="small"
                        // aria-label="a dense table"
                        aria-label="custom pagination table sticky"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        borderRight: 1.5,
                                        borderColor: "white",
                                        fontSize: "17px",
                                    }}
                                >
                                    No
                                </StyledTableCellHead>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        borderRight: 1.5,
                                        borderColor: "white",
                                        fontSize: "17px",
                                    }}
                                >
                                    お問い合わせ番号
                                </StyledTableCellHead>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        borderRight: 1.5,
                                        borderColor: "white",
                                        fontSize: "17px",
                                    }}
                                >
                                    進捗
                                </StyledTableCellHead>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        borderRight: 1.5,
                                        borderColor: "white",
                                        fontSize: "17px",
                                    }}
                                >
                                    着手日時
                                </StyledTableCellHead>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        borderRight: 1.5,
                                        borderColor: "white",
                                        fontSize: "17px",
                                    }}
                                >
                                    到着日時
                                </StyledTableCellHead>
                                <StyledTableCellHead
                                    align="center"
                                    sx={{
                                        fontSize: "17px",
                                    }}
                                >
                                    判定結果
                                </StyledTableCellHead>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
                                (row, index) => (
                                    <TableRow key={row.index}>
                                        <StyledTableCellBody
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            style={{ width: 20 }}
                                            align="center"
                                            component="th"
                                            scope="row"
                                        >
                                            {row.index}
                                        </StyledTableCellBody>
                                        <StyledTableCellBody
                                            style={{ width: 160 }}
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            align="center"
                                        >
                                            {row.inquirySearchNo}
                                        </StyledTableCellBody>
                                        <StyledTableCellBody
                                            style={{ width: 160 }}
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            align="center"
                                        >
                                            {row.progressStatus}
                                        </StyledTableCellBody>
                                        <StyledTableCellBody
                                            style={{ width: 160 }}
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            align="center"
                                        >
                                            {row.startTimeStamp}
                                        </StyledTableCellBody>
                                        <StyledTableCellBody
                                            style={{ width: 160 }}
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            align="center"
                                        >
                                            {row.arrivalTimeStamp}
                                        </StyledTableCellBody>
                                        <StyledTableCellBody
                                            style={{ width: 160 }}
                                            sx={{
                                                borderRight: 1,
                                                borderRightColor: "rgb(224,224,224)",
                                                fontSize: "16px",
                                            }}
                                            align="center"
                                        >
                                            {row.ngStatus}
                                        </StyledTableCellBody>
                                    </TableRow>
                                )
                            )}

                            {emptyRows > 0 && (
                                <TableRow style={{ height: 35.88 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                        <StyledFooter style={{ fontFamily: "Klee One" }}>
                            <StyledRow style={{ fontFamily: "Klee One" }}>
                                <ThemeProvider theme={theme}>
                                    <StyledPagination
                                        style={{ fontFamily: "Klee One" }}
                                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                        colSpan={6}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "rows per page",
                                            },
                                            native: true,
                                        }}
                                        // sx={{ fontFamily: "Klee One" }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </ThemeProvider>
                            </StyledRow>
                        </StyledFooter>
                    </Table>
                </TableContainer>
            </div>
        </div>
    );
}
export default TableComponent;
