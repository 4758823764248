// export const urlAddress = "http://ec2-13-125-237-233.ap-northeast-2.compute.amazonaws.com:8080/";
// export const urlAddress = "http://ec2-54-180-26-76.ap-northeast-2.compute.amazonaws.com/";

// export const urlAddress = "http://localhost/";
// export const urlAddress = "http://localhost:8080/";
// export const urlAddress = "http://ec2-3-38-182-155.ap-northeast-2.compute.amazonaws.com/";
// export const urlAddress = "http://192.168.4.146/";

//xserver
// export const urlAddress = "http://162.43.4.227/";
// export const urlAddress = "https://honda-logi16787.com/";
// export const urlAddress = "http://162.43.7.26/";
export const urlAddress = "https://wataya-mori.com/";

export const aokuro = "rgb(38, 43, 60)";
